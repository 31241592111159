.pt_product-search-noresult .primary-content .section-header {
    margin-top: 0;
}

.footer-copy .countries-container > a {
    background: url(../images/korea.png) left center no-repeat;
}

.plp-actions .plp-actions-button .sort-by label {
    display: none;
}

.plp-actions .plp-actions-button .plp-actions-toggle-refine,
.plp-actions .plp-actions-button .sort-by select {
    font-size: 14px;
}

.plp-actions .plp-actions-button .plp-actions-toggle-refine {
    @include respond(largeUp) {
        width: 282px;
    }
}

.plp-actions .plp-actions-button .sort-by select {
    @include respond(small) {
        width: 152px;
    }
}

.plp-actions .plp-actions-button .sort-by {
    @include respond(largeUp) {
        width: auto;
    }
}

#refined-by-attributes {
    @include respond(largeUp) {
        width: calc(100% - 568px);
    }
}

.pt_account.pt_account_kr {
	& ~ .ui-dialog {
		#dialog-container {
			#edit-address-form,
			#CreditCardForm {
				#daum-postcodes-drowdown {
					div[id^="__daum__layer"] {
						@include respond(small) {
							overflow: visible !important;
							width: 120% !important;
							transform: scale(0.8);
							iframe {
								left: -12.5% !important;
								width: 104% !important;
							}
						}
						@include respond(largeUp) {
							width: 100% !important;
						}
					}
				}
			}
		}
	}
}

#mini-cart {
	.mini-cart-buttons .mini-cart-button {
		font-size: 18px;
		padding-left: 8px;
		padding-right: 8px;
		@media screen and (min-width: 375px) {
			font-size: 24px;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

#wrapper.pt_order.pt_order_kr {
	#main {
        .details-box {
            .flex-details {
                @include respond(small) {
                    max-height: 77px;
                }
            }
        }
		.order-confirmation-details {
			table.order-shipment-table {
				tr {
					td.line-item-details {
						.product-list-item {
							@include respond(largeUp) {
								float: left;
								width: 100%;
							}
							@include respond(large) {
								float: left;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}

#wrapper.pt_order-confirmation {
	#main {
		.order-confirmation-details {
			table.order-shipment-table {
				tr {
					td.line-item-details {
						.product-list-item {
							@include respond(largeUp) {
								float: left;
								width: 100%;
							}
							@include respond(large) {
								float: left;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}

#pdpMain {
	.product-col-1.product-image-container {
		.product-badge {
			@include respond(small) {
				top: 0;
			}
		}
	}
}

.pdp-main .product-col-2 {
	@include respond(largeUp) {
		box-sizing: content-box;
	}
}

#wrapper.pt_order #main .details-box .flex-details {
    .payment-summary {
        display: none;
    }
    @include respond(small) {
        max-height: 80px;
        .arrow-tog {
            top: 26px;
            &.arrow-tog-1 {
                top: 36px;
            }
        }
    }
}

.guest-lookup .order-block {
    p.tracker {
        @include respond(largeUp) {
            margin-top: 5px;
        }
    }
}

.search-bar__description {
	margin-top: 0;
	font-family: $titillium;
	font-weight: bold;
	font-size: 11px;
}

.search-bar__notes {
	font-family: $titillium;
	color: red;
}

.overview-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    margin: rem(10px) auto rem(50px);
    font-size: rem(16px);
    order: 4;

    @include respond(largeUp) {
        max-width: 1200px;
        width: 80%;
    }

    @include respond(1320px) {
        max-width: 1300px;
        width: calc(100% - 492px - var(--overviewLeft));
        min-width: 700px;
        padding-top: rem(200px);
        margin-left: 0;
        margin-right: 0;
        position: relative;
        left: var(--overviewLeft);
    }
}

.product-tile {
    .product-name {
        padding: 0 !important;
        width: 100% !important;
    }

    .product-pricing,
    .product-swatches,
    .product-category {
        position: static !important;
    }
    .product-swatches {
        @include respond(largeUp) {
            margin-top: 0 !important;
        }

        .swatch-list {
            @include respond(largeUp) {
                margin-top: 0 !important;
            }
        }
    }

    .product-tile-details {
        @include respond(largeUp) {
            min-height: 175px !important;
        }
    }
}
